
import { Chart, registerables } from 'chart.js';
import { defineComponent, onMounted, computed, onUnmounted, ref } from 'vue';
import { BarChart, useBarChart } from 'vue-chart-3';
import partnerAPI from '@/service/partnerAPI';
import convertNumWithComma from '@/utils/convertNumWithComma';
import format from 'date-fns/format';
import useUtils from '@/mixins/useUtils';

Chart.register(...registerables);

export default defineComponent({
  name: 'Dashboard',
  components: {
    BarChart,
  },
  methods: {},
  setup() {
    const { maskingId } = useUtils();
    const period = ref<any>('all');
    const dashBoardData = ref<any>({
      graph: [],
    });

    const periodOptions = [
      { value: 'all', label: '기간 : 전체' },
      { value: 'this_month', label: '기간 : 이번달' },
      {
        value: 'last_month',
        label: `기간 : ${new Date().getMonth()}월`,
      },
      {
        value: 'two_month_ago',
        label: `기간 : ${new Date().getMonth() - 1}월`,
      },
    ];

    const fetchDashBoardData = async () => {
      const { data } = await partnerAPI.partnerDashboard.getUsingGET({
        dashboardTerm: period.value,
      });
      dashBoardData.value = data as any;
    };

    const changePeriod = (target) => {
      console.log(target);
      period.value = target;
      fetchDashBoardData();
    };

    const salesTrend = () => {
      const trendData = computed(() => dashBoardData.value.graph || []);
      const options = {
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y / 10000 + '만';
                }
                return label;
              },
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              borderColor: '#ddd',
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              borderDash: [2, 2],
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, values) {
                if (index === 0) {
                  return value;
                }
                if (index % 2 == 0) {
                  return value / 10000 + '만';
                }
              },
            },
          },
        },
      };
      const chartData = computed(() => ({
        labels: trendData.value.map((value) => value.month + '월'),
        datasets: [
          {
            barPercentage: 0.3,
            categoryPercentage: 0.3,
            label: '판매금액',
            data: trendData.value.map((value) => value.salesAmount),
            backgroundColor: '#171717',
          },
          {
            barPercentage: 0.3,
            categoryPercentage: 0.3,
            label: '정산금액',
            data: trendData.value.map((value) => value.settlementAmount),
            backgroundColor: '#49CDDA',
          },
        ],
      }));
      const { barChartProps, barChartRef } = useBarChart({
        chartData,
      });
      return {
        options,
        barChartProps,
        barChartRef,
      };
    };

    onMounted(() => {
      (
        document.querySelector('.AppLayout__main') as HTMLFormElement
      ).classList.add('bg-white');

      fetchDashBoardData();
    });
    onUnmounted(() => {
      (
        document.querySelector('.AppLayout__main') as HTMLFormElement
      ).classList.remove('bg-white');
    });

    const displayedAt = (createdAt) => {
      const milliSeconds = new Date().getTime() - new Date(createdAt).getTime();
      const seconds = milliSeconds / 1000;
      if (seconds < 60) return `방금전`;
      const minutes = seconds / 60;
      if (minutes < 60) return `${Math.floor(minutes)}분전`;
      const hours = minutes / 60;
      if (hours < 24) return `${Math.floor(hours)}시간전`;
      const days = hours / 24;
      if (days < 7) return `${Math.floor(days)}일전`;
      const weeks = days / 7;
      if (weeks < 5) return `${Math.floor(weeks)}주전`;
      const months = days / 30;
      if (months < 12) return `${Math.floor(months)}개월전`;
      const years = days / 365;
      return `${Math.floor(years)}년전`;
    };

    return {
      period,
      changePeriod,
      maskingId,
      periodOptions,
      dashBoardData,
      ...salesTrend(),
      convertNumWithComma,
      format,
      displayedAt,
    };
  },
});
