<template>
  <div>
    <header class="flex items-center w-full justify-between">
      <h1 class="uppercase font-bold text-lg mb-6">Overview</h1>
      <select
        class="
          border-black
          FormSelect
          h-10
          border
          inline-flex
          px-4
          appearance-none
          focus:outline-none
          rounded-none
          text-sm
          period-select
          mb-4
        "
        :value="period"
        @input="changePeriod($event.target.value)"
      >
        <option
          v-for="item in periodOptions"
          :key="item.value"
          :value="item.value"
        >
          {{ item.label }}
        </option>
      </select>
    </header>
    <ul class="flex">
      <li class="overview__item h-40 px-8 pt-6 pb-2 bg-white">
        <header class="flex items-center justify-between mb-6">
          <strong class="font-medium text-sm">결제완료 금액</strong>
          <span class="font-light text-sm">{{ convertNumWithComma(dashBoardData.salesCount, '') }}건</span>
        </header>
        <p class="text-center">
          <strong class="font-bold text-xl">{{ convertNumWithComma(dashBoardData.salesAmount, '') }}</strong>
          <small class="font-light text-sm ml-1">원</small>
        </p>
      </li>
      <li class="overview__item h-40 px-8 py-6 bg-white relative">
        <header class="flex items-center justify-between mb-6">
          <strong class="font-medium text-sm">정산완료</strong>
          <span class="font-light text-sm">{{ convertNumWithComma(dashBoardData.settlementCount, '') }}건</span>
        </header>
        <p class="text-center">
          <strong class="font-bold text-xl">{{ convertNumWithComma(dashBoardData.settlementAmount, '')}}</strong>
          <small class="font-light text-sm ml-1">원</small>
        </p>
        <p class="text-sm absolute bottom-4 left-6">
          수수료 : <strong class="font-medium">{{ convertNumWithComma(dashBoardData.settlementFee, '') }}</strong> 원
        </p>
      </li>
      <li class="overview__item h-40 px-8 py-6 bg-white">
        <header class="flex items-center justify-between mb-6">
          <strong class="font-medium text-sm">판매중 작품수</strong>
        </header>
        <p class="text-center">
          <strong class="font-bold text-xl">{{ convertNumWithComma(dashBoardData.productSaleCount, '') }}</strong>
          <small class="font-light text-sm ml-1">건</small>
        </p>
      </li>
      <li class="overview__item h-40 px-8 py-6 bg-white">
        <header class="flex items-center justify-between mb-6">
          <strong class="font-medium text-sm">판매된 작품수</strong>
        </header>
        <div class="flex justify-between">
          <div class="text-center w-1/2">
            <p>
              <strong class="font-bold text-xl">{{ convertNumWithComma(dashBoardData.productBidCompleteCount, '') }}</strong>
              <small class="font-light text-sm ml-1">건</small>
            </p>
            <small class="text-sm block mt-2">낙찰</small>
          </div>
          <div class="h-8 w-px bg-gray-300 mt-4 mx-6"></div>
          <div class="text-center w-1/2">
            <p>
              <strong class="font-bold text-xl">{{ convertNumWithComma(dashBoardData.productBuynowCompleteCount, '') }}</strong>
              <small class="font-light text-sm ml-1">건</small>
            </p>
            <small class="text-sm block mt-2">Buy Now</small>
          </div>
        </div>

      </li>
      <li class="overview__item h-40 px-8 py-6 bg-white">
        <header class="flex items-center justify-between mb-6">
          <strong class="font-medium text-sm">유찰된 작품수</strong>
        </header>
        <p class="text-center">
          <strong class="font-bold text-xl">{{ convertNumWithComma(dashBoardData.productOutbidCount, '') }}</strong>
          <small class="font-light text-sm ml-1">건</small>
        </p>
      </li>
    </ul>
    <div class="flex justify-between">
      <div class="mt-14 dashboard-content">
        <header class="dashboard-header flex items-center justify-between mb-6">
          <h2 class="font-bold text-lg">
            판매진행중
            <small class="text-sm text-gray-400 font-light ml-2"
              >{{ format(new Date(), 'yyyy/MM/dd') }} 현재</small
            >
          </h2>
          <router-link to="/app/sales/list?start=&end=&categoryDepth1Id=&orderStatus=&salesStatus=sale&enableBid=undefined&keyword=&biddingExist=undefined&page=0&pageSize=10&isFilter=true&sortBy=undefined" class="text-sm underline font-light"
            >전체보기</router-link
          >
        </header>
        <table class="ListTable table-fixed w-full text-center text-sm mt-6">
          <thead class="bg-gray-200 border border-gray-300">
            <tr class="h-10">
              <td class="w-48">LOT#</td>
              <td class="">상품명</td>
              <td class="">비딩현황</td>
              <td class="w-36">현재가</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in dashBoardData.onSaleProductList"
              :key="index"
              class="h-28 border-b border-gray-300"
              @click="() => $router.push(`/app/sales/detail/${item.productId}`)"
            >
              <td class="cursor-pointer pl-4">
                <div class="flex items-center">
                  <div
                    v-if="item.productImageList?.length > 0"
                    class="w-15 h-15 bg-gray-100 bg-cover"
                    :style="{
                      backgroundImage: `url(${item.productImageList[0].url})`,
                    }"
                  ></div>
                  <div
                    v-else
                    class="w-15 h-15 bg-gray-100 bg-cover"
                    :style="{
                      backgroundImage: `url(https://via.placeholder.com/120x120/F3F4F6?text=x)`,
                    }"
                  ></div>
                  <div class="ml-4">{{ item.lot }}</div>
                </div>
              </td>
              <td class="text-left cursor-pointer">
                <div>
                  {{ item.artistName }}
                </div>
                <div>{{ item.title }}</div>
              </td>
              <td class="text-center cursor-pointer">
                <div>
                  <strong>{{
                    convertNumWithComma(item.biddingCount, '')
                  }}</strong
                  >명
                </div>
              </td>
              <td class="cursor-pointer text-right">
                <p class="pr-4">
                  <strong>{{ convertNumWithComma(item.nowPrice, '') }}</strong
                  >원
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-14 dashboard-content">
        <header class="dashboard-header flex items-center justify-between mb-6">
          <h2 class="font-bold text-lg">
            판매추이
            <small class="text-sm text-gray-400 font-light ml-2"
              >최근 6개월</small
            >
          </h2>
        </header>
        <div class="sale-chart-area">
          <bar-chart v-bind="barChartProps" height="250" :options="options" />
        </div>
        <article>
          <header
            class="dashboard-header flex items-center justify-between mb-2 mt-6"
          >
            <h2 class="font-bold text-lg">1:1 문의</h2>
            <router-link to="/app/qna" class="text-sm underline font-light"
              >전체보기</router-link
            >
          </header>
          <ul>
            <li
              v-for="(qna, index) in dashBoardData.qnaList"
              :key="index"
              class="dashboard-qna__item py-4 pl-2"
            >
              <router-link :to="`/app/qna?productId=${qna.productId}&memberId=${qna.memberId}`" class="flex items-center">
                <div
                  class="w-10 h-10 bg-gray-100 bg-cover rounded-full"
                  :style="{
                    backgroundImage: `url(${qna.profileImage})`,
                  }"
                ></div>
                <strong class="text-sm font-normal mx-4">{{
                  maskingId(qna.email)
                }}</strong>
                <span class="text-sm text-red-600">{{
                  displayedAt(qna.createdAt)
                }}</span>
                <p class="text-sm ml-4 truncate max-w-md">
                  {{ qna.memo }}
                </p>
              </router-link>
            </li>
          </ul>
        </article>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Chart, registerables } from 'chart.js';
import { defineComponent, onMounted, computed, onUnmounted, ref } from 'vue';
import { BarChart, useBarChart } from 'vue-chart-3';
import partnerAPI from '@/service/partnerAPI';
import convertNumWithComma from '@/utils/convertNumWithComma';
import format from 'date-fns/format';
import useUtils from '@/mixins/useUtils';

Chart.register(...registerables);

export default defineComponent({
  name: 'Dashboard',
  components: {
    BarChart,
  },
  methods: {},
  setup() {
    const { maskingId } = useUtils();
    const period = ref<any>('all');
    const dashBoardData = ref<any>({
      graph: [],
    });

    const periodOptions = [
      { value: 'all', label: '기간 : 전체' },
      { value: 'this_month', label: '기간 : 이번달' },
      {
        value: 'last_month',
        label: `기간 : ${new Date().getMonth()}월`,
      },
      {
        value: 'two_month_ago',
        label: `기간 : ${new Date().getMonth() - 1}월`,
      },
    ];

    const fetchDashBoardData = async () => {
      const { data } = await partnerAPI.partnerDashboard.getUsingGET({
        dashboardTerm: period.value,
      });
      dashBoardData.value = data as any;
    };

    const changePeriod = (target) => {
      console.log(target);
      period.value = target;
      fetchDashBoardData();
    };

    const salesTrend = () => {
      const trendData = computed(() => dashBoardData.value.graph || []);
      const options = {
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y / 10000 + '만';
                }
                return label;
              },
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              borderColor: '#ddd',
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              borderDash: [2, 2],
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, values) {
                if (index === 0) {
                  return value;
                }
                if (index % 2 == 0) {
                  return value / 10000 + '만';
                }
              },
            },
          },
        },
      };
      const chartData = computed(() => ({
        labels: trendData.value.map((value) => value.month + '월'),
        datasets: [
          {
            barPercentage: 0.3,
            categoryPercentage: 0.3,
            label: '판매금액',
            data: trendData.value.map((value) => value.salesAmount),
            backgroundColor: '#171717',
          },
          {
            barPercentage: 0.3,
            categoryPercentage: 0.3,
            label: '정산금액',
            data: trendData.value.map((value) => value.settlementAmount),
            backgroundColor: '#49CDDA',
          },
        ],
      }));
      const { barChartProps, barChartRef } = useBarChart({
        chartData,
      });
      return {
        options,
        barChartProps,
        barChartRef,
      };
    };

    onMounted(() => {
      (
        document.querySelector('.AppLayout__main') as HTMLFormElement
      ).classList.add('bg-white');

      fetchDashBoardData();
    });
    onUnmounted(() => {
      (
        document.querySelector('.AppLayout__main') as HTMLFormElement
      ).classList.remove('bg-white');
    });

    const displayedAt = (createdAt) => {
      const milliSeconds = new Date().getTime() - new Date(createdAt).getTime();
      const seconds = milliSeconds / 1000;
      if (seconds < 60) return `방금전`;
      const minutes = seconds / 60;
      if (minutes < 60) return `${Math.floor(minutes)}분전`;
      const hours = minutes / 60;
      if (hours < 24) return `${Math.floor(hours)}시간전`;
      const days = hours / 24;
      if (days < 7) return `${Math.floor(days)}일전`;
      const weeks = days / 7;
      if (weeks < 5) return `${Math.floor(weeks)}주전`;
      const months = days / 30;
      if (months < 12) return `${Math.floor(months)}개월전`;
      const years = days / 365;
      return `${Math.floor(years)}년전`;
    };

    return {
      period,
      changePeriod,
      maskingId,
      periodOptions,
      dashBoardData,
      ...salesTrend(),
      convertNumWithComma,
      format,
      displayedAt,
    };
  },
});
</script>

<style lang="scss" scoped>
.period-select {
  width: 19%;
}
.overview__item {
  width: 19%;
  border: 1px solid #dddddd;
  &:hover {
    border: 1px solid #49cdda;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }
  & + .overview__item {
    margin-left: 1.25%;
  }
}
.dashboard-content {
  width: 740px;
}
.sale-chart-area {
}
.dashboard-qna__item {
  border-top: 1px solid #dddddd;
  &:hover {
    background: #f5f9ff;
  }
}
select.FormSelect {
  background: url('../assets/ico_selectbar_arrow.png') no-repeat;
  background-size: 10px;
  background-position: calc(100% - 14px) center;
}
</style>
